<template>
  <div class="page">
    <div class="header">
      <div class="left">
        <div style="width: 530px; height: 157px">
          <div class="left-item bg1" v-show="solid == 1">
            <div class="title">入驻美蜂互联网医院</div>
            <div class="desc">
              欢迎入驻美蜂互联网医院，来美蜂获得更多曝光，被更多患者发现
            </div>
            <div class="join" @click="movejoin">立即入驻</div>
          </div>
          <div class="left-item bg2" v-show="solid == 2">
            <div class="title">请进行资质认证</div>
            <div class="desc">欢迎来到美蜂互联网医院，资质认证通过后可以使用更多功能</div>
            <div class="join" @click="toPage('/doctorInfo/join')">立即认证</div>
          </div>
          <div class="left-dots" v-show="solid != 0">
            <div
              class="dot"
              :class="solid == 1 ? 'dot-active' : ''"
              @click="slider(1)"
            ></div>
            <div
              class="dot"
              :class="solid == 2 ? 'dot-active' : ''"
              @click="slider(2)"
            ></div>
          </div>
        </div>

        <div class="left-item2">
          <div class="title">我的诊室</div>
          <div style="display: flex; margin: 20px 0">
            <div class="item-menu" @click="open('/doctorInfo/order')">
              <img src="../../static/join/home_ico_order@2x.png" class="item-icon" />
              <div style="width: 90px; text-align: center">
                <div style="font-size: 14px">问诊订单</div>
                <div style="font-size: 12px">待接单: {{ online.online }}</div>
              </div>
            </div>
            <div class="item-menu" @click="open('/doctorInfo/underline')">
              <img src="../../static/join/doctor_ico_hospital@2x.png" class="item-icon" />
              <div style="width: 90px; text-align: center">
                <div style="font-size: 14px">到院就诊</div>
                <div style="font-size: 12px">待就诊: {{ online.locate }}</div>
              </div>
            </div>
            <div class="item-menu">
              <img src="../../static/join/homr_ico_reply@2x.png" class="item-icon" />
              <div style="width: 90px; text-align: center">
                <div style="font-size: 14px">问诊答复</div>
                <div style="font-size: 12px">待答复: {{ online.reply }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="time-title">
          <b-icon class="check-icon" icon="caret-left-fill" @click="prev"></b-icon>
          {{ date.year }}年{{ date.month }}月
          <b-icon class="check-icon" icon="caret-right-fill" @click="next"></b-icon>
        </div>
        <div class="title">近期预约表</div>
        <div style="display: flex; height: 28px; line-height: 28px">
          <div
            style="width: 50px; text-align: center; font-size: 14px"
            v-for="(item, index) in weeks"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
        <div style="display: flex; flex-wrap: wrap; margin-top: 5px">
          <div
            class="week"
            v-for="(item, i) in days"
            :key="i"
            :class="Number(item.day) == Number(active) ? 'now' : ''"
            @click="checkDay(item)"
          >
            <div
              class="day"
              :class="
                date.year > now.year || date.month > now.month || item.day >= now.day
                  ? ''
                  : 'color-gray'
              "
            >
              {{
                Number(item.day) == date.day &&
                date.year == now.year &&
                date.month == now.month
                  ? "今"
                  : item.day
              }}
            </div>
            <div v-show="item.has" class="day-txt">有预约</div>
          </div>
        </div>
      </div>
    </div>

    <div class="table-area">
      <div class="title">预约详情</div>
      <div class="no-data" v-show="checkList.length == 0">
        <div>
          <img
            src="../../static/my/empty-image-default.png"
            style="height: 300px; width: auto"
          />
          <div style="text-align: center">暂无数据</div>
        </div>
      </div>
      <div class="tb" style="margin: 10px 0" v-show="checkList.length > 0">
        <b-list-group>
          <b-list-group-item
            v-for="(item, i) in checkList"
            :key="i"
            style="display: flex; align-items: center; color: #999"
          >
            <div style="width: 350px">
              <b-icon icon="person" style="margin: 0 5px; color: #fd9852"></b-icon
              >预约客户：
              <img
                src="../../static/doctor/avatar.png"
                style="width: 42px; height: 42px; border-radius: 50%; margin: 0 5px"
              />
              {{ item.user_info.nickname }}
            </div>
            <div style="width: 350px">
              <b-icon icon="clock" style="margin: 0 5px; color: #fd9852"></b-icon
              >预约时间：
              {{ $timeFormat(item.reservetime, "y-mm-dd hh:MM") }}
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>

    <div class="opera-area">
      <div class="title">操作手册文档</div>
      <div class="tab-area">
        <div
          class="tab"
          v-for="(item, index) in list"
          :key="index"
          :class="tab == index ? 'tab-active' : ''"
          @click="changeTab(index)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="listcontent">
        <div class="item" v-for="(item, index) in listcontent" :key="index">
          <img :src="$host + item.image" style="width: 218px; height: 218px" />
          <div style="width: 200px; margin-left: 9px; margin-top: 13px">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>

    <div class="mask" v-show="modalShow == true"></div>
    <div class="custom-modal" v-show="modalShow == true">
      <div class="modal-bg">
        <div class="modal-title">认证后使用该功能</div>
        <div class="btn-a">
          <div class="modal-btn btn-sure" @click="toPage('/doctorInfo/join')">立即认证</div>
          <div class="modal-btn btn-cancel" @click="modalShow = false">暂不认证</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      weeks: ["日", "一", "二", "三", "四", "五", "六"],
      online: {
        online: "0",
        locate: "0",
        reply: "0",
      },
      modalShow: false,
      date: {
        year: "",
        month: "",
        day: "",
      },
      now: {
        year: "",
        month: "",
        day: "",
      },
      days: [],
      active: "",
      tabs: ["新手必修", "问诊技巧", "平台规范", "优秀案例"],
      tab: 0,
      list: [],
      listcontent: [],
      solid: 1,
      hasworking: "false",
      checkList: [],
      md: {
        padding: 0,
      },
    };
  },
  watch: {
    tab: function (val) {
      this.getOpera(val);
    },
  },
  computed: {
    userInfo() {
      let data = JSON.parse(this.$cache.getCache("doctorInfo")).id;
      return data;
    },
  },
  methods: {
    slider(i) {
      this.solid = 0;
      setTimeout(() => {
        this.solid = i;
      }, 100);
    },
    getOnline() {
      this.$request.getonline({ id: this.userInfo }).then((res) => {
        let { data, code } = res;
        if (code == 1) {
          this.online = data;
        }
      });
    },
    checkDay(data) {
      if (
        this.date.year > this.now.year ||
        this.date.month > this.now.month ||
        data.day >= this.now.day
      ) {
        console.log("test");
        this.active = data.day;
        this.checkList = data.li;
      } else {
        return;
      }
    },
    getTime() {
      let date = new Date();
      this.date.year = date.getFullYear();
      this.date.month = date.getMonth() + 1;
      this.date.day = date.getDate();
      this.now.year = date.getFullYear();
      this.now.month = date.getMonth() + 1;
      this.now.day = date.getDate();
      let time = this.date.year + "/" + this.date.month + "/01 00:00:01";
      let str = date.getTime(time) / 1000;
      this.active = date.getDate()
      this.getDatas(str);
    },
    getDatas(timestr) {
      this.$request.appointmentlist({ datetime: timestr }).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          // console.log(data)
          let list = [];
          for (let item in data) {
            let obj = {};
            if (data[item].length > 0) {
              obj.has = true;
            }
            obj.li = data[item];
            obj.day = item.split("-")[2];
            list.push(obj);
          }
          let week = new Date().getDay(timestr);
          // console.log(week)
          for (let i = 0; i < week; i++) {
            list.unshift({});
          }
          this.days = list;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    getCate() {
      this.$request.cate({}).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          let list = [];
          data.forEach((item, index) => {
            let obj = {
              name: item.title,
              id: item.id,
            };
            if (index < 4) {
              this.tabs.push({
                name: item.title,
              });
              list.push(obj);
            }
          });
          this.list = list;
          this.getOpera(list[0].id);
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    changeTab(i) {
      this.tab = i;
    },
    getOpera() {
      let data = {
        manual_id: this.list[this.tab].id,
        page: 1,
        limit: 8,
      };
      this.$request.listcontent(data).then((res) => {
        if (res.code == 1) {
          this.listcontent = res.data.items;
          console.log(this.listcontent);
        } else {
          this.$store.commit("showToast", res.msg);
        }
      });
    },
    toPage(url) {
      this.$router.push({
        path: url,
      });
    },
    getWorking() {
      this.$request.working({}).then((res) => {
        let { data, code } = res;
        if (code == 1) {
          
          if (data.id) {
            console.log(data.id)
            this.hasworking = "true";
          }else {
            console.log(data.id+'+++')
            this.hasworking = "false";
          }
        }
      });
    },
    next() {
      this.active = "";
      if (this.date.month < 12) {
        this.date.month++;
      } else {
        this.date.month = "1";
        this.date.year++;
      }
      this.renderTime();
    },
    prev() {
      if (this.date.year > this.now.year || this.date.month > this.now.month) {
        this.active = "";
        if (this.date.month > 1) {
          this.date.month--;
        } else {
          this.date.month = "12";
          this.date.year--;
        }
        this.renderTime();
      }
    },
    renderTime() {
      let date = new Date();
      let time = [];
      time[0] = this.date.year;
      time[1] = this.date.month > 9 ? this.date.month : "0" + this.date.month;
      time[2] = "01 00:00:01";
      let str = time.join("-");
      let timestr = parseInt(new Date(str).getTime() / 1000);
      console.log(timestr);
      console.log(date.getTime());
      this.getDatas(timestr);
    },
    movejoin() {
      if (this.hasworking == 'false') {
        this.modalShow = true;
      } else {
        this.$router.push({
          path: "/doctorinfo/hospital",
        });
      }
    },
    open(url) {
      this.$router.push({
        path: url
      })
    },
  },
  mounted() {
    this.getOnline();
    this.getTime();
    this.getCate();
    setTimeout(() => {
      this.getWorking();
    }, 100);
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
